th {
  background-color: none;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  height: 1.5rem !important;
  padding: 1.3rem !important;
  background-color: $light-blue;
  color: white !important;
}

table, th, tr, .MuiTableCell-root{
  border: 1px solid rgb(168, 167, 167) !important;
}

.MuiTable-root,
.MuiTableCell-root,
.MuiTableRow-root,
.MuiTableHead-root {
  font-size: 1.4rem !important;
}

.title-icon {
  font-size: 4rem !important;
  margin: 0 1rem;
  padding: 0.5rem;
  font-weight: 300;
  border: 0.1rem solid $light-blue-near-visible;
  color: $light-blue;
}

.text-item,
.password-item,
.select-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  & p {
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }
}
.MuiFormLabel-root {
  font-size: 1.3rem !important;
}

.MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
  font-size: 1.6rem !important;
}
.MuiMenuItem-root {
  min-width: 20rem;
  width: 100% !important;
  font-size: 1.4rem !important;
}

.password-item {
  margin-bottom: 1rem;
}

.password-mismatch .MuiOutlinedInput-root {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.MuiInputBase-input,
.MuiOutlinedInput-input,
.MuiSelect-outlined,
.MuiSelect-select{
  font-size: 1.6rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  width: 100% !important;
  min-height: 1.6rem !important;
  height: 1.6rem !important;
}

.alert-title-class{
  font-size: 2rem !important;
}
.swal2-html-container{
  font-size: 1.5rem !important;
}

.swal2-confirm, .swal2-styled{
  font-size: 1.5rem !important;
}

.selectedItem{
  background-color: $light-blue-near-visible;
}
.MuiDialog-paper{
  width: 100%;
  min-width: 29rem !important;
  max-width: 70rem;
  padding: 2rem;
}

.rc{
  padding: 0 1rem;
}

.center-label{
  font-size: 1.9rem !important;
  font-weight: bold !important;
  text-transform: uppercase;
  & span{
    font-size: 2rem; 
    font-weight: 400;
    text-transform: none;
  }
}

@include bp-tab-md{
  .rc{
    padding: 3rem 7rem;
  }
  // .center-label{
  //   font-size: 2.8rem !important;
  //   & span{
  //     font-size: 2.5rem;
  //   }
  // }
}