// Breakpoints
$bp-sm: 319px;
$bp-md: 374px;
$bp-lg: 424px;
$bp-tab: 767px;
$bp-tab-md: 1023px;
$bp-lap: 1278px;
$bp-lap-lg: 1439px;
$bp-desk: 1920px;
$bp-desk-lg: 2559px;

// Color palette
// $light-gray: #f0f1f7;
$light: #fff;
$dark: #171717;
$light-gray: #e9eaed;
$light-gray-plus: #F0F1F7;
$light-dark: #909090;
$text-gray: #848c99;
$light-text: rgb(144, 144, 144);
$hover-box: rgba(246, 246, 246, 0.3);
$blue: #192349;
$sky: #63AEFE;
$light-blue: #0267d3;
$light-blue-near-visible: #63aefe4c;
$strong-blue: #013a78;
$error: #951313;
