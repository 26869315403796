@import "../../../assets/scss/main.scss";

.content {
  &-box {
    overflow-y: scroll;
    padding-right: 1.5rem;
    padding: 2rem 0;
    &::-webkit-scrollbar {
      width: .3rem;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $light-blue;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    & button {
      background-color: $light-blue;
      font-size: 1.2rem;
      font-weight: 700;
      width: 20rem;
    }
  }
}

.ttc{
  width: 100%;
  margin-top: 2rem;
  &-container{
    max-height: 50rem;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: .3rem;
      height: 1rem;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $light-blue;
    }
  }
}

@include bp-lap {
  .content {
    &-box {
      height: 40rem;
    }
  }
}

@include bp-lap-lg {
  .content {
    &-box {
      height: 50rem;
    }
    &-actions {
      margin-top: 5rem;
    }
  }
}

.css-19kzrtu{
  padding: 0 !important;
}
