@import "../../../assets/scss/main.scss";
.rc {
  min-width: 29rem;
  & .MuiBox-root {
    padding: 0;
  }
  &-data {
    border: none;
    & h2 {
      font-size: 1.8rem;
      font-weight: 900;
      display: flex;
      align-items: center;
      & svg {
        font-size: 3rem;
        margin: 0 1rem;
        font-weight: 400;
        color: $light-blue;
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 1rem 0;
    padding: 0 2rem;
    & svg {
      color: $light-blue;
      font-size: 2rem;
    }
  }
}