@import "../../../assets/scss/main.scss";

.rc {
  min-width: 29rem;
  & .MuiBox-root {
    padding: 0;
  }
  &-header {
    width: 100%;
  }
  &-data {
    overflow: scroll;
    white-space: nowrap;
    width: 100%;
    margin: 3rem 0;
    border: 0.1rem solid $light-blue;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: -1rem 0;
  }
  &-prebuilt {
    &-headers {
      gap: 3rem;
      text-align: center;
    }
    &-item {
      gap: 2rem;
    }
    &-btns {
      & button {
        justify-content: center;
        width: 100%;
      }
    }
  }
  &-option {
    & button {
      font-size: 1.3rem;
    }
  }
}

@include bp-md {
  .rc {
    &-prebuilt {
      &-item {
        gap: 4rem;
      }
    }
  }
}


@include bp-lg {
  .rc {
    &-prebuilt {
      &-item {
        gap: 5rem;
      }
    }
  }
}

@include bp-tab {
  .rc {
    &-prebuilt {
      &-headers {
        text-align: left;
      }
      &-item{
        gap: 2rem;
      }
      &-btns {
        & button {
          justify-content: flex-start;
          width: fit-content;
        }
      }
    }
  }
}
@include bp-tab-md {
  .rc {
    padding: 3rem 4rem;
  }
}
