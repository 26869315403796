@import "../../assets/scss/main.scss";

.ei {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 2rem;
  &-item {
    width: 29rem;
    margin-bottom: 7rem;
  }
  &-header{
    margin-bottom: 2rem;
  }
  &-body{
    &-help{
      width: 100%;
      display: grid;
      place-items: end;
    }
  }
  &-table-wrapper{
    & button{
      font-size: 1.2rem;
      font-weight: 700;
      color: $light;
      background-color: $light-blue !important;
      width: 20rem;
    }
  }
  &-footer{
    display: grid;
    place-items: center;
    margin-top: 3rem;
  }

}

@include bp-md {
  .ei {
    &-item {
      width: calc($bp-md - 5rem);
    }
  }
}
@include bp-lg {
  .ei {
    &-item {
      width: calc($bp-lg - 5rem);
    }
  }
}
@include bp-tab {
  .ei {
    &-item {
      width: calc($bp-tab - 10rem);
    }
  }
}

@include bp-tab-md {
  .ei {
    &-item {
      width: calc($bp-tab-md - 10rem);
    }
  }
}
@include bp-lap-lg {
  .ei {
    &-item {
      width: $bp-lap;
    }
  }
}
