@import "../../assets/scss/main.scss";
.login {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  padding: 4rem 0;
  &-container {
    border: .1rem solid transparent;
    border-radius: 2.5rem;
    padding: 3rem 0;
    width: 29rem;
    height: 48rem;
  }
  &-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-content {
    width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    margin-bottom: 5rem;
    & img{
     width: 25rem;
    }
  }
  &-label {
    padding: 0 3rem;
    & label {
      font-size: 1.4rem;
    }
  }
  &-inputs {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    padding: 0 3rem;
    width: 100%;
    & button {
      font-size: 1.4rem;
      text-decoration: none;
      text-transform: initial;
      font-weight: 400;
      word-break:keep-all;
      color: #076cd7;
      border: none;
      margin: 2rem 0 1rem auto;
    }
  }
  &-stepper-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
    padding: 0 3rem;
    width: 100%;
    height: 100%;

    & button {
      font-size: 1.3rem;
    }
  }
}

@include bp-md {
  .login {
    &-container {
      width: 30rem;
    }
    &-content {
      width: 33rem;
    }
    &-label {
      & label {
        font-size: 1.5rem;
      }
    }
  }
}

@include bp-lg {
  .login {
    &-container {
      width: 30rem;
    }
    &-stepper-btns {
      & button {
        font-size: 1.4rem;
      }
    }
  }
}

@include bp-tab {
  .login {
    &-box{
      justify-content: center;
    }
    &-container {
      width: 40rem;
      margin: 3rem 0;
    }
    &-content{
        width: 38rem;
    }
  }
}

@include bp-lap {
  .login {
    &-container {
      margin: 7rem 0 3rem 0;
    }
  }
}