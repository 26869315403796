@import "../../assets/scss/main.scss";

.page-title {
//   width: 30rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & h2 {
    font-size: 1.7rem;
    font-weight: 700;
    color: $light-blue;
    letter-spacing: -0.1rem;
  }
  & p {
    font-size: 1.7rem;
    font-weight: 400;
    letter-spacing: -0.1rem;
  }
  & span svg {
    font-size: 2rem;
    margin: 0 1rem;
  }
}
