@import "./variables";
@import "./mixins";
@import "./typography";
@import "./components";

*, *::after, *::before{
    box-sizing: border-box;
}

html, body{
    padding: 0;
    margin: 0;
}

input, textarea, select {
    font-size: 1.6rem !important;
}

ul, li{
    padding: none;
    margin: none;
}

.active{
    background-color: $light-blue;
    & svg{
        color: white !important;
    }
    & a{
        color: white !important;
        font-weight: 800;
    }
}

.dark-bg{
    background-color: $dark;
}

.dark-bg-l{
    background-color: $light-dark;
}

.light-bg-l{
    background-color: $light-gray;
}

.dark-color{
    color: $light;
}

.light-bg{
    background-color: $dark;
}

.light-color{
    color: $light;
}