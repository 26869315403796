@import "../../assets/scss/main.scss";

.tcard {
  display: flex;
  border-radius: 2rem;
  min-width: 29rem;
  width: 100%;
  max-width: 34rem;
  height: 11rem;
  background-color: $light;
  padding: 0 2rem;
  &__left {
    border-radius: 100%;
    padding: 1rem;
    margin: 2rem 2rem 2rem 0;
    width: 5rem;
    height: 5rem;
    & svg {
      color: white;
      font-size: 3rem;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    & h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
    }
    & p {
      margin: 0;
      font-size: 3rem;
      font-weight: 700;
    }
  }
}

@include bp-tab{
  .tcard {
    &__right {
      & h3 {
        font-size: 1.5rem;
      }
      & p {
        font-size: 3rem;
      }
    }
  }
}
