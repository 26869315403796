@import "../../assets/scss/main.scss";

.dnd {
  &-files {
    border: 0.1rem solid transparent;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &-row {
    &-dropzone {
      border: 0.1rem solid transparent;
      display: grid;
      place-items: center;
      padding: 3rem 0;
      &--container {
        text-align: center;
        border: 0.2rem dashed $dark;
        border-radius: 1.5rem;
        width: 25rem;
        padding: 3rem 0;

        & svg {
          font-size: 9rem;
          color: $light-blue;
        }

        & p {
          font-size: 1.4rem;
          padding: 0 2rem;
        }

        & button {
          font-size: 1.2rem;
          font-weight: 700;
          background-color: $light-blue;
        }
      }
    }
    &-date{
      width: 25rem;
      display: flex;
      gap: 2rem;
      margin: 2rem auto 0 auto;
    }
    &-reportType{
      width: 25rem;
      margin: 0 auto;
    }
    &-uploads {
      display: grid;
      place-items: center;
      padding: 3rem 0;

      & span {
        width: 25rem;
        font-size: 1.1rem;
        font-weight: 800;
        color: $text-gray;
        text-transform: uppercase;
      }

      & ul {
        list-style: none;
        overflow: hidden scroll;
        width: 29rem;
        // height: 14rem;
        height: fit-content;
        padding: 0 1.5rem;
      }

      &--item {
        padding: 1rem 0;
        &__li {
          display: flex;
          align-items: center;
          padding: 0 1rem;

          & svg {
            font-size: 2.5rem;
          }

          & li {
            font-size: 1.4rem;
            line-height: 2.2rem;
            font-weight: 800;
            color: $dark;
            overflow: hidden;
            width: 100%;
            max-height: 6rem;
            margin-left: 1rem;
            padding: 1rem 0;
          }
          & button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0;
            transform: translateX(.2rem);
          }
        }
        &__status {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & svg {
            font-size: 2.2rem;
          }
        }
      }
    }
    &-actions {
      border: 0.1rem solid transparent;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 1.5rem 0;

      & button {
        font-size: 1.2rem;
      }
    }
  }
}

@include bp-tab {
  .dnd {
    &-files {
      width: 35rem;
    }
    &-row {
      &-dropzone {
        &--container {
          width: 28rem;
          & svg {
            font-size: 9rem;
          }
          & p {
            font-size: 1.4rem;
          }
          & button {
            font-size: 1.2rem;
          }
        }
      }
      &-date{
        width: 28rem;
      }
      &-reportType{
        width: 28rem;
      }
      &-uploads {
        & span {
          width: 28rem;
          font-size: 1.1rem;
        }
        & ul {
          width: 32rem;
        }

        &--item {
          &__li {
            & svg {
              font-size: 2.9rem;
            }
            & li {
              font-size: 1.6rem;
            }
          }
        }
      }
      &-actions {
        & button {
          font-size: 1.4rem;
        }
      }
    }
  }
}

@include bp-lap {
  .dnd {
    &-files {
      width: 45rem;
      height: 100%;
      max-height: 100vh;
    }
    &-row {
      display: flex;
      flex-direction: row;
      padding: 10rem 0;
      &-dropzone {
        align-items: start;
        height: fit-content;
        &--container {
          width: 35rem;
          height: 30rem;
          & svg {
            font-size: 9rem;
          }
          & p {
            font-size: 1.5rem;
            font-weight: 700;
          }
          & button {
            font-size: 1.2rem;
          }
        }
      }
      &-date{
        width: 35rem;
      }
      &-reportType{
        width: 35rem;
      }
      &-uploads {
        height: fit-content;
        & span {
          width: 35rem;
          font-size: 1.1rem;
        }

        & ul {
          width: 41rem;
          margin-left: 3rem;
        }

        &--item {
          &__li {
            & svg {
              font-size: 2.9rem;
            }
            & li {
              font-size: 1.6rem;
            }
            & button{
              transform: translateX(.35rem);
            }
          }
        }
      }
      &-actions {
        padding: 1rem 0;
        & button {
          font-size: 1.4rem;
        }
      }
    }
  }
}
