@import "../../assets/scss/main.scss";

.filecard{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17.5rem;
    height: 19rem;
    background-color: $light-gray;
    &:hover{
        cursor: pointer;
        background-color: $light-blue-near-visible;
    }
    &-image{
        display: grid;
        place-items: center;
        width: 100%;
        margin: 0;
        padding-top: 3rem;
        & img{
            width: 6rem;
        }
    }
    &-desc{
        overflow: hidden;
        width: 15rem;
        margin-top: 2rem;
        &-title{
            font-size: 1.3rem;
            font-weight: 700;
            white-space: nowrap;
            width: fit-content;
            margin: 0;
        }
        &-text{
            font-size: 1.3rem;
            line-height: 2rem;
            margin: 0;
            padding-top: .7rem;
        }
    }
}