@import "../../assets/scss/main.scss";

.flItem {
  margin: 1rem 0;
  padding: .7rem 0;

  &-desc {
    min-width: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      font-size: 1.4rem;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 15rem;
      width: 15rem;
      height: 100%;
      max-height: 4rem;
      margin: 0 1rem;
    }
    &__name{
        justify-content: flex-start;
    }
  }
}
