@import "../../assets/scss/main.scss";

.ucard {
  display: "flex";
  align-items: "center";
  height: 100%;
  min-width: 20rem;
  width: 100%;
  &-container {
    display: flex;
    gap: 2rem;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
  &-header {
    width: fit-content;
  }
  &-desc {
    margin: 0;
    min-width: 15rem;
  }
}
