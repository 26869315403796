// Chillax
html {
    font-size: 62.5%;
}
p, a, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, blockquote, pre, button, div{
  font-family: "Inter", sans-serif !important;
}
@font-face {
    font-family: 'Chillax-Extralight';
    src: url('../fonts/Chillax-Extralight.otf') format('opentype');
    font-weight: 100;
  }
@font-face {
    font-family: 'Chillax-Light';
    src: url('../fonts/Chillax-Light.otf') format('opentype');
    font-weight: 300;
  }
@font-face {
    font-family: 'Chillax-Regular';
    src: url('../fonts/Chillax-Regular.otf') format('opentype');
    font-weight: 400;
  }
@font-face {
    font-family: 'Chillax-Medium';
    src: url('../fonts/Chillax-Medium.otf') format('opentype');
    font-weight: 500;
  }
@font-face {
    font-family: 'Chillax-Semibold';
    src: url('../fonts/Chillax-Semibold.otf') format('opentype');
    font-weight: 600;
  }
@font-face {
    font-family: 'Chillax-Bold';
    src: url('../fonts/Chillax-Bold.otf') format('opentype');
    font-weight: 900;
  }

// Inter
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900
// .inter-<uniquifier> {
//     font-family: "Inter", sans-serif;
//     font-optical-sizing: auto;
//     font-weight: <weight>;
//     font-style: normal;
//     font-variation-settings:
//       "slnt" 0;
//   }