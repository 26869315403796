@import "../../assets/scss/main.scss";

.fc {
  padding-bottom: 7rem;

  &-container {
    display: grid;
    place-items: center;
  }
  &-item {
    display: grid;
    place-items: center;
    width: 29rem;
    padding: 3rem 0;
    &-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &-table{
    width: 100%;
    height: 70rem;
    margin: 0;
    padding: 0;
    overflow: scroll;
  }
}

@include bp-md {
  .fc {
    &-item {
      width: calc($bp-md - 3rem);
    }
  }
}

@include bp-lg {
  .fc {
    &-item {
      width: calc($bp-lg - 3rem);
    }
  }
}

@include bp-tab {
  .fc {
    &-item {
      width: calc($bp-tab - 3rem);
    }
  }
}

@include bp-tab-md {
  .fc {
    &-item {
      width: calc($bp-tab-md - 3rem);
    }
  }
}

@include bp-lap {
  .fc {
    padding: 0;
    &-container {
      display: grid;
      grid-template-columns: auto 1fr;
      justify-items: start;
      align-items: start;
      height: 100%;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      &__text-one {
        margin-left: 3.7rem;
      }
      &:nth-child(1) {
        width: fit-content;
        border-right: 1rem solid #dadce1;
      }
      &:not(:first-child) {
        margin: 0 2rem;
      }
    }
    &-table{
      margin-left: -5rem;
      width: calc(100% - 7rem);
    }
  }
}
