@mixin bp-md {
    @media screen and (min-width: $bp-md) {
      @content;
    }
  }
  @mixin bp-lg {
    @media screen and (min-width: $bp-lg) {
      @content;
    }
  }
  @mixin bp-tab {
    @media screen and (min-width: $bp-tab) {
      @content;
    }
  }
  @mixin bp-tab-md {
    @media screen and (min-width: $bp-tab-md) {
      @content;
    }
  }
  @mixin bp-lap {
    @media screen and (min-width: $bp-lap) {
      @content;
    }
  }
  @mixin bp-lap-lg {
    @media screen and (min-width: $bp-lap-lg) {
      @content;
    }
  }
  @mixin bp-desk {
    @media screen and (min-width: $bp-desk) {
      @content;
    }
  }
  @mixin bp-desk-lg {
    @media screen and (min-width: $bp-desk-lg) {
      @content;
    }
  }