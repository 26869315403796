@import "../../assets/scss/main.scss";

.uc {
  padding: 2rem;
  &-header {
    width: 100%;
  }
  &-advice {
    display: none;
  }
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-form {
      padding-top: 2rem;
      width: 25rem;
      height: 100% ;
    }
  }
  &-body {
    width: 25rem;
  }
}

@include bp-md {
  .uc {
    &-container {
      &-form {
        width: 35rem;
      }
    }
    &-body {
      width: 35rem;
    }
  }
}
@include bp-lg {
  .uc {
    &-container {
      &-form {
        width: 37rem;
      }
    }
    &-body {
      width: 37rem;
    }
  }
}
@include bp-tab {
  .uc {
    &-container {
      &-form {
        width: 45rem;
      }
    }
    &-body {
      width: 45rem;
    }
  }
}
@include bp-tab-md {
  .uc {
    &-container {
      &-form {
        width: 55rem;
      }
    }
    &-body {
      width: 55rem;
    }
  }
}
@include bp-lap {
  .uc {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    &-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
      &-form {
        width: 65rem;
        padding: 3rem 4rem;
        margin-top: 2rem;
      }
    }
    &-body {
      width: 55rem;
    }
    &-advice {
      display: flex;
      width: 60rem;
      height: 100%;
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10rem;
        width: 100%;
        height: fit-content;
      }
      &-header {
        width: 50rem;
        & img {
          width: 100%;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        & h3 {
          font-size: 3rem;
          font-weight: 900;
          margin: 1rem 0;
        }
        & p {
          margin: 0;
          font-size: 1.8rem;
          line-height: 3rem;
          font-weight: 200;
        }
      }
    }
  }
}

@include bp-lap-lg {
  .uc {
    &-container {
      justify-content: space-evenly;
      &-form {
        width: 65rem;
        padding: 3rem 4rem;
      }
    }
    &-advice {
      width: 70rem;
      &-header {
        width: 70rem;
      }
      &-text {
        & h3 {
          font-size: 3.5rem;
        }
        & p {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
    }
  }
}
