@import "../../../assets/scss/main.scss";

.uf {
  width: 100%;
  height: 100%;
  &-container {
    width: 100%;
    height: 100%;
  }
  &-files {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;
    margin-top: 3rem;
    padding: 2rem 4rem;
    &-view{
      display: flex;
      justify-content: flex-start;
      margin-top: 3rem;
     & svg{
      font-size: 2rem;
      margin: 0;
      padding: 0;
     }
     &__option{
      display: flex;
      justify-content: center;
     }
    }
  }
  &-menu {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 300;

    // Mobile bottom menu
    &-bottom,
    &-right {
      position: fixed;
      bottom: 0;
      background-color: $dark;
      color: white;
      width: 100vw;
      margin-left: -1.6rem;
      padding-bottom: 3rem;
      z-index: 500;

      &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 1rem;
        &-item {
          display: flex;
          align-items: center;
          margin-right: 1rem;
          height: 4rem;
          & p {
            font-size: 1.4rem;
            margin-left: 0.5rem;
          }
          & svg {
            font-size: 2rem;
            color: white;
          }
          & button {
            background: none;
            border: none;
            &:hover{
              cursor: pointer;
            }
          }
        }
      }
      &__body {
        padding: 2rem 1.5rem 0 1.5rem;
        &-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 2.4rem;
          & span {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $text-gray;
          }
          & p {
            font-size: 1.2rem;
            font-weight: 500;
            margin: 0.7rem 0 0 0;
          }
          & button {
            background-color: $error !important;
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
    }
    // Screens right menu
    &-right {
      display: none;
    }
  }
  &-list{
    height: 100%;
    max-height: 50rem;
    padding: 1rem 2rem;
    overflow-x: scroll;
    margin: 2rem auto 0 auto;
    &::-webkit-scrollbar{
      width: .3rem;
      height: 1rem;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $light-blue;
    }
    &__titles{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      margin-bottom: 2.5rem;
      &-item{
        min-width: 15rem;
        width: 15rem;
        margin: 0 1rem;
        text-align: center;
      }
    }
  }
  &-pagination{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;

    & svg{
      font-size: 2.5rem;
    }
  }
}
@include bp-tab {
  .uf {
    &-files {
      justify-content: flex-start;
    }
  }
}

@include bp-tab-md {
  .uf {
    &-menu {
      &-bottom {
        display: none;
      }
      &-right {
        display: block;
        bottom: 0;
        right: 0;
        width: 35rem;
        height: calc(100% - 7rem);
        &__header {
          justify-content: space-between;
          padding: 1rem 2rem;
        }
        &__body {
            padding: 2rem;
            margin-top: 1rem;
            &-item {
              margin-bottom: 4rem;
              & button {
                font-size: 1.2rem;
              }
            }
          }
      }
    }
  }
}