@import "../../../assets/scss/main.scss";

.navbar {
  position: relative;
  background-color: $dark;
  z-index: 98;
  width: 100%;
  height: 7rem;
  margin-top: -1rem;
  &-nav {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $dark;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 7rem;
    z-index: 100 !important;
    &__logo {
      & img {
        width: 12rem;
      }
    }
    &__menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 0 1.5rem;
      & figure{
        margin: .5rem 0 0 1rem;
      }
      & svg {
        font-size: 2.5rem;
        color: $light;
      }
    }
    &__options {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 2rem 2.5rem;
      width: 100%;
      height: 100%;
      & button{
        font-size: 1.2rem;
        color: $light;
      }

      & svg {
        font-size: 2rem;
      }
    }
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $dark;
    overflow: scroll;
    padding: 3rem 4rem;
    width: 29rem;
    min-height: 100vh;
    height: 100%;
    z-index: 99;
    overflow: scroll !important;
    &__box{
      margin-top: 7rem;
      width: 100%;
    }
    &__user{
      display: flex;
      align-items: center;
      margin-top: 3rem;
      &-info{
        & p{
          color: white;
          font-size: 1.8rem;
          margin: 0 0 .5rem 2rem;
        }
        & span{
          color: $light-text;
          text-transform: uppercase;
          font-size: 1.1rem;
          font-weight: 700;
          margin: 0 0 0 2rem;
        }
      }
    }
    &__section{
      width: 100%;
      margin: 3rem 0;

      & p{
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .03rem;
        color: $text-gray;
        margin: 2rem 0;
      }

      &-item{
        font-weight: 500;
        text-transform: capitalize;
        border: .1rem solid transparent;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 1rem 0;
        padding: .5rem 0;

        &:hover{
          background-color: $hover-box;
          transition: .2s ease-in-out;
        }

        & a{
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 1.3rem;
          text-decoration: none;
          color: $light-text;
          padding: .5rem 1rem;
          width: 100%;
        }
        & svg{
          font-size: 2rem;
          color: $light-text;
          margin-left: 1rem;
        }

      }
    }

    &__logo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: -.5rem 0 0 1rem;
      padding-left: 3rem;
      width: 100%;
      & img {
        width: 12rem;
        z-index: 105;
      }
    }
  }
}